<template>
    <div class="main">
        <div class="box">
            <h1 class="title-about" v-html="$t('teams.title')"></h1>
            <team-item 
                v-for="(item, idx) in teams"
                :key="idx"
                :team="item"
                >
            </team-item>
        </div>
    </div>
</template>

<script>
import TeamItem from "./../components/TeamItem.vue";

export default {
  name: "Team",
  components : { TeamItem },
  data() {
    return {
      teams : []
    }
  },
  methods : {
    getData() {
      this.teams = [
      {
          img : require("@/assets/teams/Laurito.png"),
          name : "Philippe Laurito",
          job : this.$t("teams.laurito.job"),
          desc : this.$t("teams.laurito.desc"),
          order : 1
        },
        {
          img : require("@/assets/teams/papillon.png"),
          name : "Nicolas Papillon",
          job : this.$t("teams.papillon.job"),
          desc : this.$t("teams.papillon.desc"),
          order : 2
        },
        // {
        //   img : require("@/assets/teams/reynaud.png"),
        //   name : "François-Henri Reynaud",
        //   job : this.$t("teams.reynaud.job"),
        //   desc : this.$t("teams.reynaud.desc"),
        //   order : 3
        // },
        {
          img : require("@/assets/teams/droulout.jpg"),
          name : "Thomas Droulout",
          job : this.$t("teams.droulout.job"),
          desc : this.$t("teams.droulout.desc"),
          order : 4
        },
        // {
        //   img : require("@/assets/teams/Beyer.png"),
        //   name : "Nikolaus Beyer",
        //   job : this.$t("teams.beyer.job"),
        //   desc : this.$t("teams.beyer.desc"),
        //   order : 4
        // },
        // {
        //   img : require("@/assets/teams/Vazquez.png"),
        //   name : "Nathalie Vazquez",
        //   job : this.$t("teams.vazquez.job"),
        //   desc : this.$t("teams.vazquez.desc"),
        //   order : 5
        // },
      ]
    }
  },
  mounted() {
    this.getData();
  },
  watch : {
    '$i18n.locale' : function() {
      this.getData();
    }
  }
}
</script>